import React from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../components/CONSTS";
import Layout from "../components/Layout";

export default function PropertyData() {
  const { id } = useParams();
  const [property, setProperty] = React.useState<any>();
  const [listing, setListing] = React.useState<any>();
  React.useEffect(() => {
    if (id) {
      fetch(`${API_URL}/properties/${id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Token: String(localStorage.getItem("userToken")),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setProperty(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  React.useEffect(() => {
    if (property?.mlsNumber) {
      fetch(
        `${API_URL}/listings/query/${JSON.stringify({
          where: { listingId: property.mlsNumber },
        })}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Token: String(localStorage.getItem("userToken")),
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (Array.isArray(data) && data.length === 1) {
            setListing(JSON.parse(data[0].data));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [property]);

  return (
    <Layout>
      {property && property.data && (
        <pre>
          {property.province === "Ontario" ? "TRREB" : "REBGV"} DATA:
          <br />
          <hr />
          {JSON.stringify(JSON.parse(property.data), null, 2)}
        </pre>
      )}
      {listing && (
        <pre>
          <br />
          <br />
          CREA DATA:
          <br />
          <hr />
          {JSON.stringify(listing, null, 2)}
        </pre>
      )}
    </Layout>
  );
}
