import React from "react";
import styles from "../styles/Contact.module.css";
import ContactForm, { ContactType } from "./ContactForm";
import { BC_OFFICE, ON_OFFICE } from "./CONSTS";

export default function Contact() {
  const [office, setOffice] = React.useState("B.C. Office");
  return (
    <div className={styles.container}>
      <div className={styles.title}>CONTACT US</div>
      <div className={styles.form}>
        <div>OFFICE</div>
        <select
          value={office}
          onChange={(e) => {
            setOffice(e.target.value);
          }}
        >
          <option disabled>Select Our Office</option>
          <option value={BC_OFFICE}>B.C. Office</option>
          <option value={ON_OFFICE}>O.N. Office</option>
        </select>
        <ContactForm contactType={ContactType.ContactUs} emailTo={office} />
      </div>
    </div>
  );
}
