import React from "react";
import { useParams } from "react-router-dom";
import { PropertyFileInterface } from "../components/Interfaces";
import styles from "../styles/PropertyGallery.module.css";
import { ReactComponent as ArrowIcon } from "../icons/right-arrow.svg";
import { API_URL } from "../components/CONSTS";
import Layout from "../components/Layout";

export default function PropertyGallery() {
  const { id } = useParams();
  const [propertyImages, setPropertyImages] = React.useState<
    PropertyFileInterface[]
  >([]);
  const [activeImageIndex, setActiveImageIndex] = React.useState(-1);
  React.useEffect(() => {
    fetch(`${API_URL}/properties/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Token: String(localStorage.getItem("userToken")),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setPropertyImages(
            data.propertyFiles
              .filter(
                (file: PropertyFileInterface) => file.fileType === "IMAGE"
              )
              .sort(
                (a: PropertyFileInterface, b: PropertyFileInterface) =>
                  (parseInt(a.fileName.split(".")[0]) || 0) -
                  (parseInt(b.fileName.split(".")[0]) || 0)
              )
          );
        }
      });
  }, [id]);
  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (activeImageIndex < propertyImages.length - 1) {
      setActiveImageIndex(activeImageIndex + 1);
    } else {
      setActiveImageIndex(0);
    }
  };
  const handlePrev = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (activeImageIndex > 0) {
      setActiveImageIndex(activeImageIndex - 1);
    } else {
      setActiveImageIndex(propertyImages.length - 1);
    }
  };
  const handleClickImage = (event: React.MouseEvent<HTMLImageElement>) => {
    event.stopPropagation();
  };
  return (
    <Layout>
      <div className={styles.header}>
        <h1>Gallery</h1>
      </div>
      <div className={styles.propertyImages}>
        {propertyImages.map((image, index) => (
          <img
            key={image.id}
            src={image.url}
            alt={image.fileName}
            onClick={() => setActiveImageIndex(index)}
          />
        ))}
      </div>
      <div>
        {activeImageIndex > -1 && (
          <div
            className={styles.overlay}
            onClick={() => setActiveImageIndex(-1)}
          >
            <div className={styles.modal}>
              <button className={styles.prevButton} onClick={handlePrev}>
                <ArrowIcon />
              </button>
              <img
                src={propertyImages[activeImageIndex].url}
                alt={propertyImages[activeImageIndex].fileName}
                style={{ width: "100%", height: "100%" }}
                onClick={handleClickImage}
              />
              <button className={styles.nextButton} onClick={handleNext}>
                <ArrowIcon />
              </button>
              <button
                className={styles.closeButton}
                onClick={() => setActiveImageIndex(-1)}
              >
                +
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
