import React from "react";
import styles from "../styles/ContactForm.module.css";
import { API_URL, SMTP_SENDER } from "./CONSTS";

export enum ContactType {
  ContactUs = "CONTACT US",
  RequestInfo = "REQUEST INFO",
}

export default function ContactForm({
  contactType = ContactType.RequestInfo,
  additionalMailContent = {},
  emailTo,
}: {
  contactType?: ContactType;
  additionalMailContent?: { [key: string]: string };
  emailTo?: string;
}) {
  const [mail, setMail] = React.useState<{ [key: string]: string }>({
    ...additionalMailContent,
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errorFields, setErrorFields] = React.useState<string[]>([]);
  const [status, setStatus] = React.useState("");
  const reqiredFields = ["firstname", "lastname", "email"];
  const validate = (fieldName: string, value: string) => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (
      fieldName !== "email" &&
      reqiredFields.includes(fieldName) &&
      value?.length === 0
    ) {
      setErrorFields((errorFields) => [...errorFields, fieldName]);
    } else if (fieldName === "email" && !emailRegex.test(value)) {
      if (errorFields.indexOf(fieldName) === -1) {
        setErrorFields((errorFields) => [...errorFields, fieldName]);
      }
    } else {
      setErrorFields((errorFields) =>
        errorFields.filter((f) => f !== fieldName)
      );
    }
  };

  return (
    <div className={`${styles.form} contact-form`}>
      <div
        className={reqiredFields.includes("firstname") ? styles.required : ""}
      >
        FIRST NAME
      </div>
      <input
        type="text"
        name="firstname"
        placeholder="Please enter your first name"
        onChange={(e) => {
          validate("firstname", e.target.value);
          setMail({ ...mail, firstname: e.target.value });
        }}
        className={errorFields.includes("firstname") ? styles.errorInput : ""}
      />
      {errorFields.includes("firstname") && (
        <div className={styles.error}>Please enter a valid first name</div>
      )}
      <div
        className={reqiredFields.includes("lastname") ? styles.required : ""}
      >
        LAST NAME
      </div>
      <input
        type="text"
        name="lastname"
        placeholder="Please enter your last name"
        onChange={(e) => {
          validate("lastname", e.target.value);
          setMail({ ...mail, lastname: e.target.value });
        }}
        className={errorFields.includes("lastname") ? styles.errorInput : ""}
      />
      {errorFields.includes("lastname") && (
        <div className={styles.error}>Please enter a valid last name</div>
      )}
      <div className={reqiredFields.includes("email") ? styles.required : ""}>
        EMAIL
      </div>
      <input
        type="text"
        name="email"
        placeholder="Your email address"
        onChange={(e) => {
          validate("email", e.target.value);
          setMail({ ...mail, email: e.target.value });
        }}
        className={errorFields.includes("email") ? styles.errorInput : ""}
      />
      {errorFields.includes("email") && (
        <div className={styles.error}>Please enter a valid email address</div>
      )}
      <div>PHONE</div>
      <input
        type="text"
        name="phone"
        placeholder="Your contact phone"
        onChange={(e) => {
          setMail({ ...mail, phone: e.target.value });
        }}
      />
      <div>COMPANY</div>
      <input
        type="text"
        name="company"
        placeholder="Your company"
        onChange={(e) => {
          setMail({ ...mail, company: e.target.value });
        }}
      />
      <div>MESSAGE</div>
      <textarea
        name="message"
        placeholder="Please enter your question here"
        onChange={(e) => {
          setMail({ ...mail, message: e.target.value });
        }}
        rows={5}
      />
      {status === "" && (
        <button
          onClick={() => {
            validate("firstname", mail.firstname);
            validate("lastname", mail.lastname);
            validate("email", mail.email);
            if (errorFields.length === 0) {
              const to = emailTo || SMTP_SENDER;
              const from = SMTP_SENDER;
              const cc = undefined;
              const bcc = undefined;
              const subject = `${contactType} From NuCommercial`;
              const text = JSON.stringify(mail);
              let html =
                '<table style="width:100%;border: 1px solid;border-collapse: collapse;">';
              for (const key of Object.keys(mail)) {
                if (mail[key]) {
                  html =
                    html +
                    '<tr><td style="width:40%;border: 1px solid;border-collapse: collapse;">' +
                    key +
                    '</td><td style="width:60%;border: 1px solid;border-collapse: collapse;">' +
                    mail[key] +
                    "</td></tr>";
                }
              }

              html = html + "</table>";
              setStatus("SENDING");
              fetch(`${API_URL}/mail`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Token: String(localStorage.getItem("userToken")),
                },
                body: JSON.stringify({
                  from,
                  to,
                  cc,
                  bcc,
                  subject,
                  text,
                  html,
                }),
              })
                .then((res) => res.json())
                .then((json) => {
                  if (json.error) {
                    setStatus("ERROR");
                  } else {
                    setStatus("SENT");
                  }
                });
            }
          }}
        >
          {contactType === ContactType.ContactUs ? "CONTACT US" : "SEND"}
        </button>
      )}
      {status === "SENDING" && (
        <button style={{ cursor: "wait" }}>
          SENDING
        </button>
      )}
      {status === "SENT" && (
        <button style={{ backgroundColor: "#999999", cursor: "default" }}>
          ✔ SENT
        </button>
      )}
      {status === "ERROR" && (
        <button style={{ backgroundColor: "#999999", cursor: "default" }}>
          ✘ ERROR - PLEASE TRY LATER
        </button>
      )}
    </div>
  );
}
