import React from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../components/CONSTS";

export default function Listings() {
  const { province, city } = useParams();
  const [listings, setListings] = React.useState<any>();
  const [whereString, setWhereString] = React.useState(
    `{"lastUpdated":{"gte":"${new Date().toISOString()}"}}`
  );
  const cities = [
    "Toronto",
    "Markham",
    "Richmond Hill",
    "North York",
    "Scarborough",
    "Mississauga",
    "Vaughan",
    "Oakville",
    "Etobicoke",
    "Aurora",
    "Newmarket",
    "Brampton",
    "East Gwillimbury",
    "King",
    "Whitchurch-Stouffville",
    "Georgina",
    "Pickering",
    "Caledon",
    "Ajax",
    "Whitby",
    "Oshawa",
    "Uxbridge",
    "Brock",
    "Scugog",
    "Milton",
    "Burlington",
    "Halton Hills",
    "Clarington",
    "Windsor",
  ];
  return (
    <div
      style={{
        margin: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        <input
          value={whereString}
          onChange={(e) => setWhereString(e.target.value)}
        />
        <button
          onClick={() => {
            if (province && city && whereString) {
              try {
                const whereJson = JSON.parse(whereString);
                fetch(
                  `${API_URL}/listings/query/${JSON.stringify({
                    select: {
                      listingId: true,
                      longitude: true,
                      latitude: true,
                      lastUpdated: true,
                      listingContractDate: true,
                      transactionType: true,
                      province: true,
                      city: true,
                      community: true,
                      photoLastUpdated: true,
                    },
                    where: {
                      ...whereJson,
                      province,
                      city:
                        city?.toLowerCase() === "all"
                          ? {
                              in: cities,
                            }
                          : city,
                    },
                    orderBy: { lastUpdated: "asc" },
                  })}`,
                  {
                    method: "GET",
                    headers: new Headers({
                      "Content-Type": "application/json",
                      Token: String(localStorage.getItem("userToken")),
                    }),
                  }
                )
                  .then((res) => res.json())
                  .then((data) => {
                    setListings(data);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } catch {
                console.error("Invalid JSON");
              }
            }
          }}
        >
          Search
        </button>
      </div>
      {listings?.length} listings
      <pre>{JSON.stringify(listings, null, 2)}</pre>
    </div>
  );
}
