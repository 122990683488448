import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function PageNotFound() {
  return (
    <div>
      <Header />
      <p>404</p>
      <Footer/>
    </div>
  );
}
