import React from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "../styles/AboutDetail.module.css";
import Contact from "../components/Contact";
import { ABOUT_US } from "../components/CONSTS";
import { useNavigate, useParams } from "react-router-dom";

interface MemberInterface {
  name?: string;
  photo?: string;
  title?: string;
  summary?: string;
  description: string;
  biography?: string[];
}

export default function About() {
  const { memberName } = useParams();
  const navigate = useNavigate();
  const [member, setMember] = React.useState<MemberInterface>({description: ""});
  React.useEffect(() => {
    const findMember = ABOUT_US.find(
      (member) => member.name === memberName?.replace("-", " ")
    );
    if (findMember) {
      setMember(findMember);
    } else {
      navigate("/about");
    }
  }, [memberName, navigate]);
  return (
    <Layout>
      <Header />
      <div className={styles.mainContainer}>
        <h4>{`Home / About Us / ${memberName}`}</h4>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.memberDetail}>
              <img src={member.photo} alt="" className={styles.image} />
              <h1>{member.name}</h1>
              <p className={styles.memberTitle}>{member.title}</p>
              <div className={styles.description}>{member.description}</div>
              {member.biography && (
                <ul className={styles.biography}>
                  {member.biography.map((bio, index) => (
                    <li key={index}>{bio}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <Contact />
        </div>
      </div>
      <Footer />
    </Layout>
  );
}
