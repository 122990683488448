import React from "react";
import styles from "../styles/Property.module.css";
import { formatCurrency, formatSize } from "../components/Utilities";
import { Link } from "react-router-dom";
import { PropertyInterface } from "./Interfaces";

export default function Property({
  property,
}: {
  property: PropertyInterface;
}) {
  const [pictureUrl, setPictureUrl] = React.useState(property.pictureUrl);
  let propertyAddress = "(Inquire For Address)";
  if (property.isDisplayAddress && property.address) {
    propertyAddress = `${
      property.isDisplayUnit || !property.unit
        ? property.address
        : property.address.replace(property.unit, "***")
    }${property.city ? ", " + property.city : ""}`;
  }

  const subjectSpace = property.subjectSpace
    ? formatSize(property.subjectSpace)
    : "Inquire For Information";

  let price = "Inquire For Price";
  if (property.isDisplayPrice && property.price) {
    price = `${formatCurrency(property.price)} ${
      property.priceDescription?.toLowerCase().includes("sq ft") ||
      property.priceDescription?.toLowerCase().includes("sq m")
        ? property.priceDescription
        : ""
    }`;
  }

  return (
    <Link
      key={`property-${property.id}`}
      className={styles.paper}
      to={`/property/${property.id}`}
      target="_blank"
    >
      <img
        src={pictureUrl || "../propertyImagePlaceholder.png"}
        alt=""
        className={styles.image}
        loading="lazy"
        onError={(e) => {
          setPictureUrl("../propertyImagePlaceholder.png");
        }}
      ></img>
      <div>
        <div className={styles.row}>
          {property.listingType === "SALE" ? (
            <div
              className={styles.label}
              style={{ backgroundColor: "#4260CC" }}
            >
              For Sale
            </div>
          ) : (
            <div
              className={styles.label}
              style={{ backgroundColor: "#47AC3A" }}
            >
              For Lease
            </div>
          )}
          <div className={styles.label} style={{ backgroundColor: "#B3B8E6" }}>
            {property.propertyType.displayName || property.propertyType.name}
          </div>
        </div>
        <div className={styles.price}>{price}</div>
        <div className={styles.address}>{propertyAddress}</div>
        <div className={styles.address}>{subjectSpace}</div>
      </div>
    </Link>
  );
}
