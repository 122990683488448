import React from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "../styles/About.module.css";
import Contact from "../components/Contact";
import { ABOUT_US } from "../components/CONSTS";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <Layout>
      <Header />
      <div className={styles.mainContainer}>
        <h4>Home / About Us</h4>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.banner}></div>
            <h2>ABOUT US</h2>
            <div>
              Nu Commercial is the ultimate destination for all your commercial
              real estate needs. Our online platform makes it simple to find the
              ideal commercial property for your business needs. We have a vast
              array of commercial listings, including MLS and off-market
              properties. Our user-friendly For Seller and For Buyer sections
              cater to anyone seeking to buy, sell, or lease commercial
              properties. With our experienced team, you won't miss out on any
              opportunities to maximize your commercial real estate investments.
              Contact us now to start your search and take your business to new
              heights!
            </div>
            <h2>OUR TEAM</h2>
            <div className={styles.teamMembers}>
            {ABOUT_US.map((member, index) => (
              <div className={styles.row} key={index}>
                <img
                  src={member.photo}
                  alt=""
                  className={styles.image}
                />
                <div className={`${styles.column} ${styles.memberInfo}`}>
                  <h3>{member.name}</h3>
                  <div className={styles.memberTitle}>{member.title}</div>
                  {member.summary && <div className={styles.description}>{member.summary}</div>}
                  <Link
                    className={styles.more}
                    to={`/about/${member.name.replace(" ", "-")}`}
                  >
                    Show More
                  </Link>
                </div>
              </div>
            ))}
            </div>
            
          </div>
          <Contact />
        </div>
      </div>
      <Footer />
    </Layout>
  );
}
