import React from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../components/CONSTS";

export default function Listing() {
  const { id } = useParams();
  const [listing, setListing] = React.useState<any>();
  React.useEffect(() => {
    if (id) {
      fetch(`${API_URL}/listings/sync/${id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Token: String(localStorage.getItem("userToken")),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setListing(JSON.parse(data.data));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);
  return (
    <div
      style={{
        margin: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <pre>{JSON.stringify(listing, null, 2)}</pre>
    </div>
  );
}
