import React from "react";
import { PushpinInterface } from "./Interfaces";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default function OpenStreetMap({
  pushpins,
  onBoundsChanged,
  onReady,
}: {
  pushpins?: PushpinInterface[];
  onBoundsChanged?: ({ bounds }: { bounds: any }) => void;
  onReady?: (map: any) => void;
}) {
  const [center, setCenter] = React.useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = React.useState(13);
  const [calculatedPushpins, setCalculatedPushpins] = React.useState<any[]>([]);

  const calculatePushpins = React.useCallback(
    ({
      minLatitude,
      maxLatitude,
      minLongitude,
      maxLongitude,
    }: {
      minLatitude: number;
      maxLatitude: number;
      minLongitude: number;
      maxLongitude: number;
    }) => {
      const newPushpins = [];
      if (pushpins && pushpins.length > 1) {
        const dividedBy = 7;
        for (let i = 0; i < dividedBy; i++) {
          const east =
            maxLongitude + ((minLongitude - maxLongitude) / dividedBy) * i;
          const west =
            maxLongitude +
            ((minLongitude - maxLongitude) / dividedBy) * (i + 1);
          for (let j = 0; j < dividedBy; j++) {
            const north =
              maxLatitude + ((minLatitude - maxLatitude) / dividedBy) * j;
            const south =
              maxLatitude + ((minLatitude - maxLatitude) / dividedBy) * (j + 1);
            const propertiesInRect = [];
            for (let k = 0; k < pushpins.length; k++) {
              if (
                pushpins[k].latitude > south &&
                pushpins[k].latitude <= north &&
                pushpins[k].longitude > west &&
                pushpins[k].longitude <= east
              ) {
                propertiesInRect.push(pushpins[k]);
              }
            }
            if (propertiesInRect.length > 0) {
              let latitude = 0;
              let longitude = 0;
              let htmlContent = `<div style="max-height:400px;overflow-y:auto;background-color:white;padding:5px;border-radius:5px;">`;
              for (let k = 0; k < propertiesInRect.length; k++) {
                latitude += propertiesInRect[k].latitude;
                longitude += propertiesInRect[k].longitude;
                htmlContent += propertiesInRect[k].metadata || "";
              }
              htmlContent += `</div>`;
              newPushpins.push({
                latitude: latitude / propertiesInRect.length,
                longitude: longitude / propertiesInRect.length,
                text: propertiesInRect.length.toString(),
                metadata: htmlContent,
                onMouseOver:
                  propertiesInRect.length === 1
                    ? propertiesInRect[0].onMouseOver
                    : undefined,
              });
            }
          }
        }
      }
      else if (pushpins && pushpins.length === 1) {
        newPushpins.push(pushpins[0]);
      }
      return newPushpins;
    },
    [pushpins]
  );

  React.useEffect(() => {
    if (pushpins && pushpins.length > 0) {
      const maxLatitude = Math.max(
        ...pushpins.map((p: PushpinInterface) => p.latitude)
      );
      const minLatitude = Math.min(
        ...pushpins.map((p: PushpinInterface) => p.latitude)
      );
      const maxLongitude = Math.max(
        ...pushpins.map((p: PushpinInterface) => p.longitude)
      );
      const minLongitude = Math.min(
        ...pushpins.map((p: PushpinInterface) => p.longitude)
      );
      if (maxLongitude - minLongitude > 20) {
        setZoom(5);
      } else if (maxLongitude - minLongitude > 10) {
        setZoom(6);
      } else if (maxLongitude - minLongitude > 5) {
        setZoom(7);
      } else if (maxLongitude - minLongitude > 2) {
        setZoom(8);
      } else if (maxLongitude - minLongitude > 1) {
        setZoom(9);
      } else if (maxLongitude - minLongitude > 0.5) {
        setZoom(10);
      } else if (maxLongitude - minLongitude > 0.3) {
        setZoom(11);
      } else if (maxLongitude - minLongitude > 0.1) {
        setZoom(12);
      }
      setCenter({
        lat: (maxLatitude + minLatitude) / 2,
        lng: (maxLongitude + minLongitude) / 2,
      });
      const newPushpins = calculatePushpins({
        minLatitude:
          minLatitude === maxLatitude ? minLatitude - 0.01 : minLatitude,
        maxLatitude:
          minLatitude === maxLatitude ? maxLatitude + 0.01 : maxLatitude,
        minLongitude:
          minLongitude === maxLongitude ? minLongitude - 0.01 : minLongitude,
        maxLongitude:
          minLongitude === maxLongitude ? maxLongitude + 0.01 : maxLongitude,
      });
      setCalculatedPushpins(newPushpins);
    }
  }, [pushpins, calculatePushpins]);

  const MapContent = () => {
    const map = useMapEvents({
      dragend(e) {
        const newPushpins = calculatePushpins({
          minLatitude: e.target.getBounds().getSouth(),
          maxLatitude: e.target.getBounds().getNorth(),
          minLongitude: e.target.getBounds().getWest(),
          maxLongitude: e.target.getBounds().getEast(),
        });
        setCalculatedPushpins(newPushpins);
        onBoundsChanged && onBoundsChanged({ bounds: e.target.getBounds() });
      },
      zoomend(e) {
        const newPushpins = calculatePushpins({
          minLatitude: e.target.getBounds().getSouth(),
          maxLatitude: e.target.getBounds().getNorth(),
          minLongitude: e.target.getBounds().getWest(),
          maxLongitude: e.target.getBounds().getEast(),
        });
        setCalculatedPushpins(newPushpins);
        onBoundsChanged && onBoundsChanged({ bounds: e.target.getBounds() });
      }
    });

    if (onReady) {
      onReady(map);
    }

    return (
      <>
        {calculatedPushpins?.map((pushpin) => (
          <Marker
            key={`${pushpin.text}${pushpin.latitude}${pushpin.longitude}`}
            position={{ lat: pushpin.latitude, lng: pushpin.longitude }}
            icon={L.icon({
              iconUrl: `data:image/svg+xml;charset=utf-8,
              ${encodeURIComponent(
                `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50">
                  <circle cx="25" cy="25" r="25" fill="#4260CC" />
                  <text text-anchor="middle" x="50%" y="60%" style="font:14px sim-song;fill:white">${
                    pushpin.text || ""
                  }</text>
                  </svg>
                `
              )}`,
            })}
            eventHandlers={{
              mouseover: pushpin.onMouseOver,
            }}
          >
            {pushpin.metadata && (
              <Popup minWidth={500} maxWidth={500}>
                <div
                  dangerouslySetInnerHTML={{ __html: String(pushpin.metadata) }}
                ></div>
              </Popup>
            )}
          </Marker>
        ))}
      </>
    );
  };

  return (
    <>
      {center.lat !== 0 && center.lng !== 0 && (
        <MapContainer
          center={center}
          zoom={zoom}
          scrollWheelZoom={true}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MapContent />
        </MapContainer>
      )}
    </>
  );
}
