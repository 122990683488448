import React from "react";
import { API_URL } from "./CONSTS";
import { UserInterface } from "./Interfaces";

interface AppContextInterface {
  user: UserInterface;
  setUser: (user: UserInterface) => void;
}

interface PropsInterface {
  children: JSX.Element;
}

const initAppContext: AppContextInterface = {
  user: {},
  setUser: () => undefined,
};

const AppContext = React.createContext<AppContextInterface>(initAppContext);

export const AppWrapper = ({ children }: PropsInterface) => {
  const [user, setUser] = React.useState<UserInterface>({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    if (localStorage.getItem("userToken")) {
      fetch(`${API_URL}/profile`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Token: String(localStorage.getItem("userToken")),
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            res.json().then((data)=>{
              setUser(data);
              localStorage.setItem("userToken", data.token);
              setLoading(false);
            });
          } else {
            setUser({});
            localStorage.removeItem("userToken");
            window.location.reload(); //User token expired, reload page to login
          }
        })
        .catch(() => {
          alert("API connection refused, please try again later");
        });
    } else {
      fetch(`${API_URL}/login`, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify({
          email: "commercial@nustreamrealty.com",
          password: "Nu5tream",
        }),
      })
        .then((res) => {          
          if (res.status === 200) {
            res.json().then((data)=>{
              setUser(data);
              localStorage.setItem("userToken", data.token);
              setLoading(false);
            });
          } else {
            setUser({});
            localStorage.removeItem("userToken");
            alert("Login failed, please try again later");
          }
        })
        .catch(() => {
          alert("API connection refused, please try again later");
        });
    }
  }, []);

  const appContext: AppContextInterface = {
    user: user,
    setUser: setUser,
  };

  return (
    <AppContext.Provider value={appContext}>
      {!loading && children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  return React.useContext(AppContext);
}
