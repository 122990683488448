import React from "react";
import styles from "../styles/Layout.module.css";
import { useLocation } from "react-router-dom";

interface PropsInterface {
  children: JSX.Element | JSX.Element[] | any | any[];
}

export default function Layout({ children }: PropsInterface) {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.root}>
      <div className={styles.children}>{children}</div>
    </div>
  );
}
