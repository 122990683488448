import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Footer.module.css";
import { LOGOS } from "./CONSTS";

export default function Footer() {
  const ref = React.useRef(null);
  const [width, setWidth] = React.useState(0);
  React.useEffect(() => {
    if (ref.current) {
      const current = ref.current as any;
      setWidth(current.offsetWidth);
    }
  }, [ref]);
  return (
    <div className={styles.container} style={{padding: width > 1000 ? "25px 127px":"" }} ref={ref}>
      <Link to="/">
        {localStorage.getItem("province") === "Ontario"
          ? LOGOS["Ontario"]["colored"]
          : LOGOS["British Columbia"]["colored"]}
      </Link>
      <div className={styles.row}>
        <div>
          <h3>CONTACT US</h3>
          <div className={styles.flexWraper}>
            <div className={styles.office}>
              <h4>Burnaby (Headquarters)</h4>
              #100 - 4555 Kingsway, Burnaby,
              <br />
              BC V5H 4T8
              <br />
              Office: 604 899 9999
              <br />
              Fax: 778 379 0785
            </div>
            <div className={styles.office}>
              <h4>Richmond</h4>
              #1550 - 4380 No.3 Rd, Richmond,
              <br />
              BC V6X 3V7
            </div>
            <div className={styles.office}>
              <h4>Toronto</h4>
              140 York Boulevard, Richmond Hill, <br />
              ON L4B 3J6
              <br />
              Office: 416 333 1111
              <br />
              Phone: 647 695 1188
            </div>
            <div className={styles.office}>
              <h4>Mississauga</h4>
              #400 - 30 Eglinton Avenue West, Mississauga,
              <br />
              ON L5R 3E7
            </div>
          </div>
        </div>
        {width > 1000 && (
          <div>
            <h3>OUR AFFILIATIONS</h3>
            <h4>
              <a href="https://johome.com/" style={{ color: "#ffffff" }}>
                Johome.com
              </a>
            </h4>
            <h4>
              <a href="https://www.nustreamrealty.com/" style={{ color: "#ffffff" }}>
                Nustreamrealty.com
              </a>
            </h4>
          </div>
        )}
      </div>
      <div>*Nu Stream Commercial is a division of Nu Stream Realty INC.</div>
      <div>
        Brokerages: British Columbia - NU STREAM REALTY INC., Ontario - NU
        STREAM REALTY (TORONTO) INC.
      </div>
      <div>© 2023 NU STREAM REALTY INC. ALL RIGHTS RESERVED.</div>
    </div>
  );
}
