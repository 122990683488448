import React from "react";
import Layout from "../components/Layout";
import styles from "../styles/Home.module.css";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { API_URL, LOGOS, PROPERTY_CLASS } from "../components/CONSTS";
import Property from "../components/Property";
import Location from "../components/Location";
import {
  PropertyInterface,
  PropertyTypeInterface,
  ProvinceInterface,
} from "../components/Interfaces";

export default function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [exclusiveProperties, setExclusiveProperties] = React.useState([]);
  const [properties, setProperties] = React.useState([]);
  const [backupProperties, setBackupProperties] = React.useState([]);
  const [provinces, setProvinces] = React.useState<ProvinceInterface[]>([]);
  const [province, setProvince] = React.useState("");
  const [city, setCity] = React.useState("");
  const [community, setCommunity] = React.useState("");
  const [neighbourhood, setNeighbourhood] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [propertyTypes, setPropertyTypes] = React.useState<
    PropertyTypeInterface[]
  >([]);
  const [propertyType, setPropertyType] = React.useState(0);
  const [listingType, setListingType] = React.useState("");

  const showTotal = 40;

  React.useEffect(() => {
    setProperties([]);
    setBackupProperties([]);
    fetch(
      `${API_URL}/properties/query/${JSON.stringify({
        take: showTotal,
        where: {
          province: province,
          status: "ACTIVE",
          listingCompany: {
            contains: "Nu Stream Realty",
          },
          mlsNumber: null,
          pictureUrl: { not: null },
          propertyClass: PROPERTY_CLASS,
        },
        include: {
          propertyType: true,
        },
        orderBy: [
          {
            listingDate: "desc",
          },
          {
            updatedAt: "desc",
          },
        ],
      })}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Token: String(localStorage.getItem("userToken")),
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setExclusiveProperties(data);
          const exclusiveTotal = data.length;
          if (exclusiveTotal < showTotal) {
            setLoading(true);
            fetch(
              `${API_URL}/properties/query/${JSON.stringify({
                take: showTotal - exclusiveTotal,
                where: {
                  province: province,
                  status: "ACTIVE",
                  listingCompany: {
                    contains: "Nu Stream Realty",
                  },
                  mlsNumber: { not: null },
                  pictureUrl: { not: null },
                  propertyClass: PROPERTY_CLASS,
                },
                include: {
                  propertyType: true,
                },
                orderBy: [
                  {
                    listingDate: "desc",
                  },
                  {
                    updatedAt: "desc",
                  },
                ],
              })}`,
              {
                method: "GET",
                headers: new Headers({
                  "Content-Type": "application/json",
                  Token: String(localStorage.getItem("userToken")),
                }),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                if (data) {
                  setProperties(data);
                  const nustreamTotal = data.length;
                  if (nustreamTotal < showTotal - exclusiveTotal) {
                    fetch(
                      `${API_URL}/properties/query/${JSON.stringify({
                        take: showTotal - exclusiveTotal - nustreamTotal,
                        where: {
                          province: province,
                          status: "ACTIVE",
                          NOT: {
                            listingCompany: {
                              contains: "Nu Stream Realty",
                            },
                          },
                          pictureUrl: { not: null },
                          propertyClass: PROPERTY_CLASS,
                        },
                        include: {
                          propertyType: true,
                        },
                        orderBy: [
                          {
                            listingDate: "desc",
                          },
                          {
                            updatedAt: "desc",
                          },
                        ],
                      })}`,
                      {
                        method: "GET",
                        headers: new Headers({
                          "Content-Type": "application/json",
                          Token: String(localStorage.getItem("userToken")),
                        }),
                      }
                    )
                      .then((res) => res.json())
                      .then((data) => {
                        if (data) {
                          setBackupProperties(data);
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }
                }
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });

    const selectedProvince = provinces.find((p) => p.name === province);
    if (selectedProvince) {
      setPropertyTypes(selectedProvince.propertyTypes);
    } else {
      setPropertyTypes([]);
    }
    setCity(province === "Ontario" ? "Toronto" : "Vancouver");
    setCommunity("");
    setNeighbourhood("");
    setPropertyType(0);
    setLocation(province === "Ontario" ? "Toronto" : "Vancouver");
  }, [provinces, province]);

  React.useEffect(() => {
    if (province) localStorage.setItem("province", province);
    localStorage.setItem("city", city);
    localStorage.setItem("community", community);
    localStorage.setItem("location", location);
    localStorage.setItem("propertyType", String(propertyType));
    localStorage.setItem("listingType", listingType);
  }, [
    province,
    city,
    community,
    neighbourhood,
    location,
    propertyType,
    listingType,
  ]);

  React.useEffect(() => {
    setLoading(true);
    fetch(
      `${API_URL}/provinces/query/${JSON.stringify({
        include: {
          cities: true,
          communities: true,
          neighbourhoods: true,
          propertyTypes: {
            where: {
              propertyClass: PROPERTY_CLASS,
            },
            orderBy: {
              order: "asc",
            },
          },
        },
      })}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Token: String(localStorage.getItem("userToken")),
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setProvinces(data);
          let defaultProvince = localStorage.getItem("province");
          if (!defaultProvince) {
            if (
              new Date().getTimezoneOffset() / 60 === 7 ||
              new Date().getTimezoneOffset() / 60 === 8
            ) {
              defaultProvince = "British Columbia";
            } else if (data.length > 0) {
              defaultProvince = data[0].name;
            }
          }
          setProvince(defaultProvince || "Ontario");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSearchClick = () => {
    navigate(
      `/search?province=${province}&city=${city}&community=${community}&neighbourhood=${neighbourhood}&location=${location}&propertyType=${propertyType}&listingType=${listingType}`
    );
  };

  return (
    <Layout>
      <div className={styles.header}>
        <Link to="/" className={styles.logo}>
          {province === "Ontario"
            ? LOGOS["Ontario"]["transparent"]
            : LOGOS["British Columbia"]["transparent"]}
        </Link>
        <div className={styles.links}>
          <Link to="/" className={styles.navMenu}>
            Home
          </Link>
          <Link to="/search" className={styles.navMenu}>
            Property Search
          </Link>
          <Link to="/seller" className={styles.navMenu}>
            For Seller
          </Link>
          <Link to="/about" className={styles.navMenu}>
            About Us
          </Link>
        </div>
        <div className={styles.search}>
          <div className={styles.row}>
            <select
              value={province}
              onChange={(e) => {
                setProvince(e.target.value);
              }}
            >
              <option disabled>Province</option>
              {provinces.map((p) => (
                <option key={`province-${p.id}`} value={p.name}>
                  {p.code}
                </option>
              ))}
            </select>
            {provinces.length > 0 && (
              <Location
                province={
                  provinces.find((p) => p.name === province) || provinces[0]
                }
                setCity={setCity}
                setCommunity={setCommunity}
                setNeighbourhood={setNeighbourhood}
                location={location}
                setLocation={setLocation}
              />
            )}
            <select
              value={listingType}
              onChange={(e) => {
                setListingType(e.target.value);
              }}
            >
              <option value={""}>All Transaction Types</option>
              <option value={"SALE"}>For Sale</option>
              <option value={"LEASE"}>For Lease</option>
            </select>
            <select
              value={propertyType}
              onChange={(e) => {
                setPropertyType(Number(e.target.value));
              }}
            >
              <option value={0}>All Property Types</option>
              {propertyTypes.map((p) => (
                <option key={`propertyType-${p.id}`} value={p.id}>
                  {p.displayName || p.name}
                </option>
              ))}
            </select>
            <button onClick={onSearchClick}>Search</button>
          </div>
        </div>
      </div>
      <div className={styles.title}>Our Featured Listings</div>
      <div className={styles.flexWraper}>
        {loading && <div>Loading...</div>}
        {exclusiveProperties.map((p: PropertyInterface) => (
          <Property key={p.id} property={p} />
        ))}
        {properties.map((p: PropertyInterface) => (
          <Property key={p.id} property={p} />
        ))}
        {backupProperties.map((p: PropertyInterface) => (
          <Property key={p.id} property={p} />
        ))}
      </div>

      <Footer />
    </Layout>
  );
}
