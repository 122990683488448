export const formatCurrency = (value: number) => {
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
};

export const formatSize = (value: number) => {
  return value > 43560
    ? `${(value / 43560).toFixed(1).replace(".0", "")} Acres`
    : `${value.toFixed(0)} SQFT`;
};

export const throttle = (callback: Function, limit: number) => {
  let wait = false;
  return (...args: any) => {
    if (!wait) {
      callback(...args);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
};

export const readFileAsDataURL = (inputFile: any) => {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };
    fileReader.onload = () => {
      resolve(fileReader.result?.toString());
    };
    fileReader.readAsDataURL(inputFile);
  });
};