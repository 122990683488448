import React from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "../styles/Admin.module.css";
import { API_URL, PROPERTY_CLASS } from "../components/CONSTS";
import Property from "../components/Property";
import { readFileAsDataURL } from "../components/Utilities";
import { PropertyFileInterface } from "../components/Interfaces";

export default function Admin() {
  const [loading, setLoading] = React.useState(false);
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const [logined, setLogined] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [provinces, setProvinces] = React.useState([]);
  const [realtors, setRealtors] = React.useState([]);
  const [propertyTypes, setPropertyTypes] = React.useState([]);
  const [properties, setProperties] = React.useState([]);
  const [property, setProperty] = React.useState<any>({
    propertyTypeId: 0,
    province: "Ontario",
    listingType: "SALE",
    status: "ACTIVE",
    city: "",
    community: "",
    neighbourhood: "",
    address: "",
    isDisplayAddress: true,
    unit: "",
    isDisplayUnit: true,
    postalCode: "",
    latitude: 0,
    longitude: 0,
    pictureUrl: "",
    price: 0,
    priceDescription: "",
    isDisplayPrice: true,
    landSize: 0,
    landSizeUnit: "SQFT",
    brochureUrl: "",
    virtualTourUrl: "",
    videoUrl: "",
    description: "",
    subjectSpace: 0,
    leasableArea: 0,
    builtYear: 0,
    zoning: "",
    category: "",
    use: "",
    listingCompany: "",
    listingDate: new Date().toISOString().split("T")[0],
    propertyPhotos: [{ url: "", fileName: "", fileType: "" }],
    propertyFiles: [{ url: "", fileName: "", fileType: "" }],
    realtorId: 0,
    realtorEmail: "",
    propertyClass: PROPERTY_CLASS,
  });

  React.useEffect(() => {
    if (localStorage.getItem("adminToken")) {
      setLogined(true);
    }
    fetch(
      `${API_URL}/properties/query/${JSON.stringify({
        where: {
          listingCompany: {
            contains: "Nu Stream Realty",
          },
          mlsNumber: null,
          propertyClass: PROPERTY_CLASS,
        },
        include: {
          propertyType: true,
          propertyFiles: true,
          propertyRealtors: {
            include: {
              realtor: true,
            },
          },
        },
        orderBy: {
          listingDate: "desc",
        },
      })}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Token: String(localStorage.getItem("userToken")),
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log(data);
          setProperties(data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (logined) {
      fetch(
        `${API_URL}/provinces/query/${JSON.stringify({
          include: {
            cities: true,
            communities: true,
            neighbourhoods: true,
            propertyTypes: {
              where: {
                propertyClass: PROPERTY_CLASS,
              },
              orderBy: {
                order: "asc",
              },
            },
          },
        })}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Token: String(localStorage.getItem("adminToken")),
          }),
        }
      )
        .then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              if (data) {
                setProvinces(data);
                let defaultProvince = localStorage.getItem("province");
                if (!defaultProvince) {
                  if (
                    new Date().getTimezoneOffset() / 60 === 7 ||
                    new Date().getTimezoneOffset() / 60 === 8
                  ) {
                    defaultProvince = "British Columbia";
                  } else if (data.length > 0) {
                    defaultProvince = data[0]["name"];
                  }
                }
                setProperty((oldProperty: any) => ({
                  ...oldProperty,
                  province: defaultProvince || "Ontario",
                }));
              }
            });
          } else {
            setLogined(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      fetch(`${API_URL}/realtors`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Token: String(localStorage.getItem("adminToken")),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setRealtors(data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [logined]);

  React.useEffect(() => {
    const province = provinces.find((p) => p["name"] === property.province);
    if (province) {
      setPropertyTypes(province["propertyTypes"]);
      setProperty((oldProperty: any) => ({
        ...oldProperty,
        propertyTypeId: province["propertyTypes"][0]["id"],
      }));
    }
  }, [provinces, property.province]);

  return (
    <Layout>
      <Header />
      <div className={styles.mainContainer}>
        {!logined && (
          <div className={styles.column}>
            Login
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button
              onClick={() => {
                fetch(`${API_URL}/login`, {
                  method: "POST",
                  headers: new Headers({ "Content-Type": "application/json" }),
                  body: JSON.stringify({
                    email: email,
                    password: password,
                  }),
                })
                  .then((res) => {
                    if (res.status === 200) {
                      res.json().then((data) => {
                        if (data.isAdmin) {
                          localStorage.setItem("adminToken", data["token"]);
                          setLogined(true);
                        } else {
                          alert("You are not admin");
                        }
                      });
                    } else {
                      alert("Login failed");
                    }
                  })
                  .catch(() => {
                    alert("API connection refused, please try again later");
                  });
              }}
            >
              Login
            </button>
          </div>
        )}
        {logined && provinces && propertyTypes && (
          <div className={styles.column}>
            <div className={styles.row}>
              <div className={styles.label}>Province:</div>
              <select
                value={property.province}
                onChange={(e) => {
                  setProperty({
                    ...property,
                    province: e.target.value,
                  });
                }}
              >
                {provinces.map((p) => (
                  <option key={`province-${p["id"]}`} value={p["name"]}>
                    {p["name"]}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Property Type:</div>
              <select
                value={property.propertyTypeId}
                onChange={(e) => {
                  setProperty({
                    ...property,
                    propertyTypeId: Number(e.target.value),
                  });
                }}
              >
                {propertyTypes.map((p) => (
                  <option key={`propertyType-${p["id"]}`} value={p["id"]}>
                    {p["displayName"] || p["name"]}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Listing Type:</div>
              <select
                onChange={(e) => {
                  setProperty({
                    ...property,
                    listingType: e.target.value,
                  });
                }}
              >
                <option>SALE</option>
                <option>LEASE</option>
              </select>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Status:</div>
              <select
                value={property.status}
                onChange={(e) => {
                  setProperty({
                    ...property,
                    status: e.target.value,
                  });
                }}
              >
                <option>ACTIVE</option>
                <option>SOLD</option>
                <option>LEASED</option>
                <option>WITHDRAWN</option>
              </select>
            </div>
            <input
              type="text"
              placeholder="City"
              value={property.city}
              onChange={(e) => {
                setProperty({
                  ...property,
                  city: e.target.value,
                });
              }}
            />
            <input
              type="text"
              placeholder="Area"
              value={property.community}
              onChange={(e) => {
                setProperty({
                  ...property,
                  community: e.target.value,
                });
              }}
            />
            <input
              type="text"
              placeholder="Neighbourhood"
              value={property.neighbourhood}
              onChange={(e) => {
                setProperty({
                  ...property,
                  neighbourhood: e.target.value,
                });
              }}
            />
            <input
              type="text"
              placeholder="Address"
              value={property.address}
              onChange={(e) => {
                setProperty({
                  ...property,
                  address: e.target.value,
                });
              }}
            />
            <div style={{ marginBottom: "10px" }}>
              Display Address:
              <input
                type="checkbox"
                placeholder="DisplayAddress"
                checked={property.isDisplayAddress}
                onChange={(e) => {
                  setProperty({
                    ...property,
                    isDisplayAddress: e.target.checked,
                  });
                }}
              />
            </div>
            <input
              type="text"
              placeholder="Unit"
              value={property.unit}
              onChange={(e) => {
                setProperty({
                  ...property,
                  unit: e.target.value,
                });
              }}
            />
            <div style={{ marginBottom: "10px" }}>
              Display Unit:
              <input
                type="checkbox"
                placeholder="DisplayUnit"
                checked={property.isDisplayUnit}
                onChange={(e) => {
                  setProperty({
                    ...property,
                    isDisplayUnit: e.target.checked,
                  });
                }}
              />
            </div>
            <input
              type="text"
              placeholder="PostalCode"
              value={property.postalCode}
              onChange={(e) => {
                setProperty({
                  ...property,
                  postalCode: e.target.value,
                });
              }}
            />
            <input
              type="number"
              placeholder="Latitude"
              value={property.latitude || ""}
              onChange={(e) => {
                setProperty({
                  ...property,
                  latitude: Number(e.target.value),
                });
              }}
            />
            <input
              type="number"
              placeholder="Longitude"
              value={property.longitude || ""}
              onChange={(e) => {
                setProperty({
                  ...property,
                  longitude: Number(e.target.value),
                });
              }}
            />
            <input
              type="number"
              placeholder="Price"
              value={property.price || ""}
              onChange={(e) => {
                setProperty({
                  ...property,
                  price: Number(e.target.value),
                });
              }}
            />
            <textarea
              placeholder="PriceDescription"
              value={property.priceDescription || ""}
              onChange={(e) => {
                setProperty({
                  ...property,
                  priceDescription: e.target.value,
                });
              }}
            />
            <div style={{ marginBottom: "10px" }}>
              Display Price:
              <input
                type="checkbox"
                placeholder="DisplayPrice"
                checked={property.isDisplayPrice}
                onChange={(e) => {
                  setProperty({
                    ...property,
                    isDisplayPrice: e.target.checked,
                  });
                }}
              />
            </div>
            <input
              type="number"
              placeholder="LandSize"
              value={property.landSize || ""}
              onChange={(e) => {
                setProperty({
                  ...property,
                  landSize: Number(e.target.value),
                });
              }}
            />
            <div style={{ marginBottom: "10px" }}>
              SQFT:
              <input
                type="checkbox"
                checked={property.landSizeUnit === "SQFT"}
                onChange={(e) => {
                  setProperty({
                    ...property,
                    landSizeUnit: e.target.checked ? "SQFT" : "ACRES",
                  });
                }}
              />{" "}
              ACRES:
              <input
                type="checkbox"
                checked={property.landSizeUnit === "ACRES"}
                onChange={(e) => {
                  setProperty({
                    ...property,
                    landSizeUnit: e.target.checked ? "ACRES" : "SQFT",
                  });
                }}
              />
            </div>
            <input
              type="text"
              placeholder="VirtualTourUrl"
              value={property.virtualTourUrl}
              onChange={(e) => {
                setProperty({
                  ...property,
                  virtualTourUrl: e.target.value,
                });
              }}
            />
            <input
              type="text"
              placeholder="VideoUrl"
              value={property.videoUrl}
              onChange={(e) => {
                setProperty({
                  ...property,
                  videoUrl: e.target.value,
                });
              }}
            />
            <textarea
              placeholder="Description"
              value={property.description || ""}
              onChange={(e) => {
                setProperty({
                  ...property,
                  description: e.target.value,
                });
              }}
            />
            <input
              type="number"
              placeholder="SubjectSpace"
              value={property.subjectSpace || ""}
              onChange={(e) => {
                setProperty({
                  ...property,
                  subjectSpace: Number(e.target.value),
                });
              }}
            />
            <input
              type="number"
              placeholder="LeasableArea"
              value={property.leasableArea || ""}
              onChange={(e) => {
                setProperty({
                  ...property,
                  leasableArea: Number(e.target.value),
                });
              }}
            />
            <input
              type="number"
              placeholder="BuiltYear"
              value={property.builtYear || ""}
              onChange={(e) => {
                setProperty({
                  ...property,
                  builtYear: Number(e.target.value),
                });
              }}
            />
            <input
              type="text"
              placeholder="Zoning"
              value={property.zoning}
              onChange={(e) => {
                setProperty({
                  ...property,
                  zoning: e.target.value,
                });
              }}
            />
            <input
              type="text"
              placeholder="Category"
              value={property.category}
              onChange={(e) => {
                setProperty({
                  ...property,
                  category: e.target.value,
                });
              }}
            />
            <input
              type="text"
              placeholder="Use"
              value={property.use}
              onChange={(e) => {
                setProperty({
                  ...property,
                  use: e.target.value,
                });
              }}
            />
            <input
              type="date"
              placeholder="ListingDate"
              value={property.listingDate}
              onChange={(e) => {
                setProperty({
                  ...property,
                  listingDate: e.target.value,
                });
              }}
            />
            Photos:
            <input
              type="file"
              multiple
              accept="image/*"
              placeholder="propertyPhotos"
              onChange={async (e) => {
                if (e.target.files && e.target.files.length > 0) {
                  const propertyPhotos = [];
                  for (let i = 0; i < e.target.files.length; i++) {
                    const url = await readFileAsDataURL(e.target.files[i]);
                    const fileName = e.target.files[i].name;
                    propertyPhotos.push({
                      url: url,
                      fileName: fileName,
                      fileType: "IMAGE",
                    });
                  }
                  setProperty({
                    ...property,
                    propertyPhotos,
                  });
                }
              }}
            />
            {property.propertyPhotos
              .filter((file: any) => file.fileType === "IMAGE")
              .map((file: any, index: number) => (
                <div key={`file-${index}`} className={styles.row}>
                  <img
                    src={file.url}
                    alt={file.fileName}
                    style={{ maxWidth: "600px", width: "600px" }}
                  />
                  <div>
                    <input
                      type="checkbox"
                      checked={property.pictureUrl === index}
                      onChange={() => {
                        setProperty({ ...property, pictureUrl: index });
                      }}
                    />
                    Default
                  </div>
                  <button
                    onClick={() => {
                      property.propertyPhotos.splice(index, 1);
                      setProperty({
                        ...property,
                      });
                    }}
                    style={{ background: "red" }}
                  >
                    Remove
                  </button>
                </div>
              ))}
            Brochure:
            <input
              type="file"
              accept="application/pdf"
              placeholder="Brochure"
              onChange={(e) => {
                if (e.target.files && e.target.files.length === 1) {
                  const fileReader = new FileReader();
                  fileReader.onloadend = (e) => {
                    if (e.target?.result) {
                      setProperty({
                        ...property,
                        brochureUrl: e.target.result.toString(),
                      });
                    }
                  };
                  fileReader.readAsDataURL(e.target.files[0]);
                }
              }}
            />
            Documents:
            <input
              type="file"
              multiple
              accept="application/pdf"
              placeholder="PropertyFiles"
              onChange={async (e) => {
                if (e.target.files && e.target.files.length > 0) {
                  const propertyFiles = [];
                  for (let i = 0; i < e.target.files.length; i++) {
                    const url = await readFileAsDataURL(e.target.files[i]);
                    const fileName = e.target.files[i].name;
                    propertyFiles.push({
                      url: url,
                      fileName: fileName,
                      fileType: "IMAGE",
                    });
                  }
                  setProperty({
                    ...property,
                    propertyFiles,
                  });
                }
              }}
            />
            {property.propertyFiles
              .filter((file: any) => file.fileType === "DOC")
              .map((file: any, index: number) => (
                <div key={`file-${index}`} className={styles.row}>
                  {file.fileName}
                  <button
                    onClick={() => {
                      property.propertyFiles.splice(index, 1);
                      setProperty({
                        ...property,
                      });
                    }}
                    style={{ background: "red" }}
                  >
                    Remove
                  </button>
                </div>
              ))}
            <input
              type="email"
              placeholder="RealtorEmail"
              value={property.realtorEmail}
              onChange={(e) => {
                setProperty({
                  ...property,
                  realtorId: 0,
                  realtorEmail: e.target.value,
                });
                if (e.target.value.length > 2) {
                  setAnchor(e.target);
                  document.body.style.overflow = "hidden";
                } else {
                  setAnchor(null);
                  document.body.style.overflow = "visible";
                }
              }}
            />
            {anchor && (
              <div
                style={{
                  position: "fixed",
                  top: anchor.getBoundingClientRect().bottom,
                  left: anchor.getBoundingClientRect().left,
                  backgroundColor: "#FFFFFF",
                  zIndex: 1,
                  border: "1px solid #E5E5E5",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  maxHeight: "300px",
                  overflowY: "auto",
                  padding: "10px",
                  width: "500px",
                }}
              >
                {realtors
                  .filter((realtor: any) =>
                    realtor.email.includes(property.realtorEmail)
                  )
                  .map((realtor: any) => (
                    <button
                      key={`realtor-${realtor["id"]}`}
                      className={styles.row}
                      style={{ width: "470px", margin: "2px" }}
                      onClick={() => {
                        setProperty({
                          ...property,
                          realtorId: realtor["id"],
                          realtorEmail: realtor["email"],
                        });
                        setAnchor(null);
                        document.body.style.overflow = "visible";
                      }}
                    >
                      {realtor["email"]}
                    </button>
                  ))}
              </div>
            )}
            {property &&
              property.city &&
              property.address &&
              property.price !== 0 &&
              property.realtorId !== 0 &&
              property.latitude !== 0 &&
              property.longitude !== 0 && (
                <button
                  disabled={loading}
                  style={{ cursor: loading ? "wait" : "pointer" }}
                  onClick={() => {
                    setLoading(true);
                    if (property.province === "Ontario") {
                      property.listingCompany =
                        "Nu Stream Realty (Toronto) Inc.";
                    } else if (property.province === "British Columbia") {
                      property.listingCompany = "Nu Stream Realty Inc.";
                    }
                    let landSize = property.landSize;
                    if (
                      property.landSize &&
                      property.landSizeUnit === "ACRES"
                    ) {
                      landSize = property.landSize * 43560;
                    }
                    property.propertyPhotos = property.propertyPhotos.filter(
                      ({ url }: { url: string }) => url !== null && url !== ""
                    );
                    property.propertyFiles = property.propertyFiles.filter(
                      ({ url }: { url: string }) => url !== null && url !== ""
                    );
                    fetch(
                      property.id
                        ? `${API_URL}/properties/${property.id}`
                        : `${API_URL}/properties`,
                      {
                        method: property.id ? "PUT" : "POST",
                        headers: new Headers({
                          "Content-Type": "application/json",
                          Token: String(localStorage.getItem("adminToken")),
                        }),
                        body: JSON.stringify({
                          ...property,
                          landSize: landSize,
                          landSizeUnit: undefined,
                        }),
                      }
                    )
                      .then((res) => {
                        if (res.status === 200) {
                          alert("Save successfully");
                          window.location.reload();
                        } else {
                          alert("Save failed");
                        }
                      })
                      .catch(() => {
                        alert("API connection refused, please try again later");
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }}
                >
                  Save
                </button>
              )}
            {!(
              property &&
              property.city &&
              property.address &&
              property.price !== 0 &&
              property.realtorId !== 0 &&
              property.latitude !== 0 &&
              property.longitude !== 0
            ) && (
              <button
                disabled={true}
                style={{ cursor: "default", backgroundColor: "grey" }}
              >
                Save
              </button>
            )}
          </div>
        )}

        <h2>已上传暗盘列表</h2>
        <div className={styles.flexWraper}>
          {logined &&
            properties &&
            properties.map((p: any) => (
              <div className={styles.column} key={`property-${p["id"]}`}>
                <Property key={p["id"]} property={p} />
                <button
                  onClick={() => {
                    const propertyPhotos = p.propertyFiles
                      .filter((file: PropertyFileInterface) => file.fileType === "IMAGE")
                      .sort(
                        (a: PropertyFileInterface, b: PropertyFileInterface) =>
                          (parseInt(a.fileName.split(".")[0]) || 0) -
                          (parseInt(b.fileName.split(".")[0]) || 0)
                      );
                    const pictureUrl = propertyPhotos.findIndex(
                      (file: PropertyFileInterface) => file.url === p.pictureUrl
                    );
                    const propertyFiles = p.propertyFiles.filter(
                      (file: PropertyFileInterface) => file.fileType !== "IMAGE"
                    );
                    let realtorId = 0;
                    let realtorEmail = "";
                    if (p.propertyRealtors.length > 0) {
                      realtorId = p.propertyRealtors[0].realtor.id;
                      realtorEmail =
                        p.propertyRealtors[0].realtor.email;
                    }
                    setProperty({
                      ...p,
                      propertyPhotos,
                      propertyFiles,
                      pictureUrl,
                      realtorId,
                      realtorEmail,
                      propertyType: undefined,
                      propertyRealtors: undefined,
                    });
                  }}
                >
                  Edit
                </button>
                <button
                  style={{ backgroundColor: "red" }}
                  onClick={() => {
                    if (
                      window.confirm("Are you sure to delete this property?")
                    ) {
                      fetch(`${API_URL}/properties/${p["id"]}`, {
                        method: "DELETE",
                        headers: new Headers({
                          "Content-Type": "application/json",
                          Token: String(localStorage.getItem("adminToken")),
                        }),
                      })
                        .then((res) => {
                          if (res.status === 200) {
                            alert("Delete successfully");
                            window.location.reload();
                          } else {
                            alert("Delete failed");
                          }
                        })
                        .catch(() => {
                          alert(
                            "API connection refused, please try again later"
                          );
                        });
                    }
                  }}
                >
                  Delete
                </button>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </Layout>
  );
}
