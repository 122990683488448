import React from "react";
import styles from "../styles/Location.module.css";
import { CityInterface, CommunityInterface, NeighbourhoodInterface, ProvinceInterface } from "./Interfaces";

export default function Location({
  province,
  setCity,
  setCommunity,
  setNeighbourhood,
  location,
  setLocation,
}: {
  province: ProvinceInterface;
  setCity: (city: string) => void;
  setCommunity: (community: string) => void;
  setNeighbourhood: (neighbourhood: string) => void;
  location: string;
  setLocation: (location: string) => void;
}) {
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const [cities, setCities] = React.useState<CityInterface[]>([]);
  const [communities, setCommunities] = React.useState<CommunityInterface[]>([]);
  const [neighbourhoods, setNeighbourhoods] = React.useState<NeighbourhoodInterface[]>([]);

  React.useEffect(() => {
    setCities(
      province.cities.filter((city: CityInterface) =>
        String(city.name).toLowerCase().includes(location.toLowerCase())
      )
    );

    setCommunities(
      province.communities.filter((community: CommunityInterface) =>
        String(community.name).toLowerCase().includes(location.toLowerCase())
      )
    );

    setNeighbourhoods(
      province.neighbourhoods.filter((neighbourhood: NeighbourhoodInterface) =>
        String(neighbourhood.name).toLowerCase().includes(location.toLowerCase())
      )
    );

  }, [province, location]);

  return (
    <>
      <input
        value={location}
        onChange={(e) => {
          setLocation(e.target.value);
          if (e.target.value.length > 2) {
            setAnchor(e.target);
            document.body.style.overflow = "hidden";
          } else {
            setAnchor(null);
            setCity("");
            setCommunity("");
            setNeighbourhood("");
            document.body.style.overflow = "visible";
          }
        }}
        placeholder="All Locations"
      ></input>
      {anchor && province && (
        <div
          style={{
            position: "fixed",
            top: anchor.getBoundingClientRect().bottom,
            left: anchor.getBoundingClientRect().left,
            backgroundColor: "#FFFFFF",
            zIndex: 101,
            border: "1px solid #E5E5E5",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            maxHeight: "50vh",
            overflowY: "auto",
          }}
        >
          <div style={{ minWidth: "300px" }}>
            {cities.map((city: CityInterface, index: number) => (
              <div key={`city-${city.id}`}>
                {index === 0 && <div className={styles.title}>City</div>}
                <div
                  className={styles.row}
                  onClick={() => {
                    setCity(city.name);
                    setCommunity("");
                    setNeighbourhood("");
                    setLocation(city.name);
                    setAnchor(null);
                    document.body.style.overflow = "visible";
                  }}
                >
                  {city.name}
                </div>
              </div>
            ))}
            {communities.map((community: CommunityInterface, index: number) => (
              <div key={`community-${community.id}`}>
                {index === 0 && <div className={styles.title}>Area</div>}
                <div
                  className={styles.row}
                  onClick={() => {
                    setCity("");
                    setCommunity(community.name);
                    setNeighbourhood("");
                    setLocation(community.name);
                    setAnchor(null);
                    document.body.style.overflow = "visible";
                  }}
                >
                  {community.name}
                </div>
              </div>
            ))}
            {neighbourhoods.map((neighbourhood: NeighbourhoodInterface, index: number) => (
              <div key={`neighbourhood-${neighbourhood.id}`}>
                {index === 0 && <div className={styles.title}>Neighbourhood</div>}
                <div
                  className={styles.row}
                  onClick={() => {
                    setCity("");
                    setCommunity("");
                    setNeighbourhood(neighbourhood.name);
                    setLocation(neighbourhood.name);
                    setAnchor(null);
                    document.body.style.overflow = "visible";
                  }}
                >
                  {neighbourhood.name}
                </div>
              </div>
            ))}
            {cities.length === 0 && communities.length === 0 && neighbourhoods.length === 0 && (
              <div className={styles.row}>No results found</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
