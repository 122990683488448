import React, { useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "../styles/PropertyDetail.module.css";
import { formatCurrency, formatSize, throttle } from "../components/Utilities";
import ContactForm from "../components/ContactForm";
import { ReactComponent as DocumentIcon } from "../icons/document.svg";
import { ReactComponent as BrochureIcon } from "../icons/brochure.svg";
import { ReactComponent as VirtualTourIcon } from "../icons/virtual-tour.svg";
import { ReactComponent as VideoIcon } from "../icons/video.svg";
import { ReactComponent as GalleryIcon } from "../icons/gallery.svg";
import { ReactComponent as MapIcon } from "../icons/map.svg";
import { ReactComponent as AvatarIcon } from "../icons/avatar.svg";
import {
  PropertyInterface,
  PropertyFileInterface,
  PropertyRealtorInterface,
} from "../components/Interfaces";
import { API_URL, BC_OFFICE, ON_OFFICE } from "../components/CONSTS";
import Layout from "../components/Layout";
import OpenStreetMap from "../components/OpenStreetMap";

// #region Fields and Anchors Constants
const keyFactLeftFields = [
  { fieldName: "listingType", label: "Listing Type" },
  { fieldName: "propertyTypeName", label: "Property Type" },
  { fieldName: "subjectSpace", label: "Subject Space" },
  { fieldName: "builtYear", label: "Year Built" },
  { fieldName: "leasableArea", label: "Leasable Area" },
  { fieldName: "mlsNumber", label: "MLS#" },
  { fieldName: "listingCompany", label: "Office" },
];

const keyFactRightFields = [
  { fieldName: "price", label: "Listing Price" },
  { fieldName: "bussinessType", label: "Business Type" },
  { fieldName: "category", label: "Category" },
  { fieldName: "use", label: "Use" },
  { fieldName: "landSize", label: "Land Size" },
  { fieldName: "zoning", label: "Zoning" },
  { fieldName: "status", label: "Status" },
];

// #endregion

// #region Request Info
const RequestInfo = ({ property }: { property: PropertyInterface }) => {
  const [activeRealtorId, setActiveRealtorId] = React.useState<number>(0);
  const displayedRealtors = property.propertyRealtors;
  if (displayedRealtors?.length === 0) {
    return (
      <div className={styles.contactForm}>
        <ContactForm
          emailTo={property.province === "Ontario" ? ON_OFFICE : BC_OFFICE}
          additionalMailContent={{
            propertyId: property.id.toString(),
            propertyAddress: property.address,
          }}
        />
      </div>
    );
  } else {
    if (activeRealtorId === 0 && displayedRealtors?.length > 0) {
      setActiveRealtorId(displayedRealtors[0]?.id);
    }
    return (
      <div className={styles.requestInfo}>
        {displayedRealtors?.map((propertyRealtor: PropertyRealtorInterface) => {
          const realtorName = `${propertyRealtor.realtor.firstName} ${propertyRealtor.realtor.lastName}`;
          const isRealtorActive = propertyRealtor.id === activeRealtorId;
          return (
            <div
              className={`${styles.realtorCard} ${
                isRealtorActive ? styles.activeRealtor : ""
              }`}
              key={propertyRealtor.realtor.id}
            >
              <div className={styles.realtorInfo}>
                {propertyRealtor.realtor.photo ? (
                  <img src={propertyRealtor.realtor.photo} alt={realtorName} />
                ) : (
                  <AvatarIcon />
                )}
                <div>
                  <span className={styles.realtorName}>
                    {realtorName.replace(/\((.+?)\)/g, "")}
                  </span>
                  <span className={styles.realtorTitle}>
                    {propertyRealtor.realtor.title}
                  </span>
                  <span className={styles.realtorPhone}>
                    {propertyRealtor.realtor.phone}
                  </span>
                  <span>{propertyRealtor.realtor.email}</span>
                </div>
              </div>
              {!isRealtorActive && (
                <button
                  className={styles.requestInfoExpandButton}
                  onClick={() => setActiveRealtorId(propertyRealtor.id)}
                >
                  CONTACT EXPERT
                </button>
              )}
              <ContactForm
                emailTo={propertyRealtor.realtor.email}
                additionalMailContent={{
                  propertyId: property.id.toString(),
                  propertyAddress: property.address,
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }
};
// #endregion

// #region Property Gallery
const PropertyImage = ({
  propertyImage,
  onClickImage,
  leadPropertyImage,
}: {
  propertyImage: PropertyFileInterface;
  onClickImage: (propertyImage: PropertyFileInterface) => void;
  leadPropertyImage: PropertyFileInterface;
}) => {
  const [image, setImage] =
    React.useState<PropertyFileInterface>(propertyImage);
  const handleClick = () => {
    const tempPropertyImage = image;
    setImage(leadPropertyImage);
    onClickImage(tempPropertyImage);
  };
  return (
    <img
      src={image.url}
      alt={image.fileName}
      onClick={() => handleClick()}
      onError={(e) => {
        e.currentTarget.src = "../propertyImagePlaceholder.png";
      }}
    />
  );
};

const PropertyImages = ({ property }: { property: any }) => {
  const nevigate = useNavigate();
  const [leadPropertyImage, setLeadPropertyImage] =
    React.useState<PropertyFileInterface>({
      id: 0,
      propertyId: property.id,
      url: "",
      fileName: "",
      fileType: "IMAGE",
    });
  if (
    property?.propertyImages?.length > 0 &&
    leadPropertyImage.url === "" &&
    leadPropertyImage.fileName === ""
  ) {
    setLeadPropertyImage(property?.propertyImages[0]);
  }
  const handleClickImage = (propertyImage: PropertyFileInterface) => {
    setLeadPropertyImage(propertyImage);
  };
  let swappableImageCount: number;
  if (property.propertyImages?.length >= 5) {
    swappableImageCount = 4;
  } else if (property.propertyImages?.length > 2) {
    swappableImageCount = 3;
  } else {
    swappableImageCount = 1;
  }
  const imagesClassName = `total-image-count-${property.propertyImages?.length}`;
  const handleViewGallery = () => {
    nevigate(`/property/gallery/${property.id}`);
  };

  return (
    <div className={`${styles.propertyImages} ${styles[imagesClassName]}`}>
      <img
        src={leadPropertyImage.url}
        alt={leadPropertyImage.fileName}
        onError={(e) => {
          e.currentTarget.src = "../propertyImagePlaceholder.png";
        }}
      />
      {property.propertyImages?.length === 2 && (
        <img
          src={property.propertyImages[1].url}
          alt={property.propertyImages[1].fileName}
          onError={(e) => {
            e.currentTarget.src = "../propertyImagePlaceholder.png";
          }}
        />
      )}
      {property.propertyImages
        ?.slice(1, swappableImageCount)
        .map((propertyImage: PropertyFileInterface) => {
          return (
            <PropertyImage
              propertyImage={propertyImage}
              onClickImage={handleClickImage}
              leadPropertyImage={leadPropertyImage}
              key={propertyImage.id}
            />
          );
        })}
      {property.propertyImages?.length > 4 && (
        <div
          className={styles.viewGallery}
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${property.propertyImages[4].url})`,
          }}
          onClick={() => handleViewGallery()}
        >
          <GalleryIcon />
          <span>VIEW GALLERY</span>
        </div>
      )}
      {property.virtualTourUrl && (
        <a
          className={styles.galleryButton}
          href={property.virtualTourUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <VirtualTourIcon />
          <span>Virtual Tour</span>
        </a>
      )}
      {property.videoUrl && (
        <a
          className={styles.galleryButton}
          href={property.videoUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <VideoIcon />
          <span>Video</span>
        </a>
      )}
      {property.brochureUrl && (
        <a
          className={styles.galleryButton}
          href={property.brochureUrl}
          rel="noopener noreferrer"
        >
          <BrochureIcon />
          <span>Brochure</span>
        </a>
      )}
    </div>
  );
};
// #endregion

// #region Title with Anchor
const TitleWithAnchor = ({
  anchorId,
  title,
}: {
  anchorId: string;
  title: string;
}) => {
  return (
    <div className={styles.anchorContainer}>
      <div className={styles.anchor} id={anchorId} />
      <h2>{title}</h2>
    </div>
  );
};
// #endregion

export default function PropertyDetail() {
  const [property, setProperty] = React.useState<any>({ price: 0 });
  const [activeAnchor, setActiveAnchor] = React.useState<string>("key-facts");
  const { id } = useParams();
  const [isShowMap, setIsShowMap] = React.useState(false);

  // #region Fetch Property
  React.useEffect(() => {
    if (id) {
      fetch(`${API_URL}/properties/${id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Token: String(localStorage.getItem("userToken")),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            let price = "Inquire For Price";
            if (data.isDisplayPrice && data.price) {
              price = `${formatCurrency(data.price)} ${
                data.priceDescription?.toLowerCase().includes("sq ft") ||
                data.priceDescription?.toLowerCase().includes("sq m")
                  ? data.priceDescription
                  : ""
              }`;
            }
            data.price = price;

            data.subjectSpace = data.subjectSpace
              ? formatSize(data.subjectSpace)
              : "Inquire For Information";

            data.leasableArea = data.leasableArea
              ? formatSize(data.leasableArea)
              : "";
            data.landSize =
              data.landSize &&
              (data.listingType === "SALE" ||
                String(data.category).toLocaleLowerCase().includes("land"))
                ? formatSize(data.landSize)
                : "";
            data.listingType =
              data.listingType === "SALE" ? "For Sale" : "For Lease";
            data.propertyImages = data.propertyFiles
              ?.filter((f: PropertyFileInterface) => f.fileType === "IMAGE")
              .sort(
                (a: PropertyFileInterface, b: PropertyFileInterface) =>
                  (parseInt(a.fileName.split(".")[0]) || 0) -
                  (parseInt(b.fileName.split(".")[0]) || 0)
              );
            data.propertyFiles = data.propertyFiles?.filter(
              (f: PropertyFileInterface) => f.fileType === "DOC"
            );
            setProperty(data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);
  // #endregion

  // #region Add/Remove Anchor highlight on scroll listener
  const handleScroll = useCallback(() => {
    const keyFactLabel = document.getElementById("key-facts");
    const documentLabel = document.getElementById("documents");
    if (
      keyFactLabel &&
      keyFactLabel.getBoundingClientRect().bottom < 0 &&
      (!documentLabel || documentLabel.getBoundingClientRect().bottom < 0) &&
      activeAnchor !== "location"
    ) {
      setActiveAnchor("location");
    } else if (
      keyFactLabel &&
      keyFactLabel.getBoundingClientRect().bottom < 0 &&
      documentLabel &&
      documentLabel.getBoundingClientRect().bottom >= 0 &&
      activeAnchor !== "documents"
    ) {
      setActiveAnchor("documents");
    } else if (
      activeAnchor !== "key-facts" &&
      keyFactLabel &&
      keyFactLabel.getBoundingClientRect().bottom >= 0
    ) {
      setActiveAnchor("key-facts");
    }
  }, [activeAnchor]);
  const throttleHandleScroll = throttle(handleScroll, 200);

  React.useEffect(() => {
    window.addEventListener("scroll", throttleHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttleHandleScroll);
    };
  }, [throttleHandleScroll]);
  // #endregion

  const handleAnchorClick = (anchorId: string) => {
    setActiveAnchor(anchorId);
    document.getElementById(anchorId)?.scrollIntoView();
  };

  let propertyAddress = "(Inquire For Address)";
  if (property.isDisplayAddress && property.address) {
    propertyAddress = `${
      property.isDisplayUnit || !property.unit
        ? property.address
        : property.address.replace(property.unit, "***")
    }${property.city ? ", " + property.city.toUpperCase() : ""}`;
  }

  return (
    <Layout>
      <Header />
      <PropertyImages property={property} />
      <div className={styles.titleRow}>
        <div className={styles.titleContent}>
          <div className={styles.titleRowLeft}>
            <h1 className={styles.titleAddress}>{propertyAddress}</h1>
            <span>{`${property.listingType} | ${
              property.propertyType?.displayName || property.propertyType?.name
            }${
              property.subjectSpace
                ? ` | Building Size: ${property.subjectSpace}`
                : ""
            }${
              property.landSize ? ` | Land Size: ${property.landSize}` : ""
            }`}</span>
          </div>
          <div className={styles.titleRowRight}>
            <span className={styles.titlePrice}>{property.price}</span>
            <span>Canadian Dollars</span>
          </div>
        </div>
      </div>
      <div className={styles.anchorRow}>
        <div className={styles.anchors}>
          <div
            className={activeAnchor === "key-facts" ? styles.activeAnchor : ""}
            onClick={() => handleAnchorClick("key-facts")}
          >
            KEY FACTS
          </div>
          {(property.brochureUrl || property.propertyFiles?.length > 0) && (
            <div
              className={
                activeAnchor === "documents" ? styles.activeAnchor : ""
              }
              onClick={() => handleAnchorClick("documents")}
            >
              DOCUMENTS
            </div>
          )}
          <div
            className={activeAnchor === "location" ? styles.activeAnchor : ""}
            onClick={() => handleAnchorClick("location")}
          >
            LOCATION
          </div>
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.propertyDetailColumn}>
          <h2>{`About ${propertyAddress}`}</h2>
          <div
            className={styles.propertyDescription}
            dangerouslySetInnerHTML={{ __html: property.description }}
          ></div>
          <TitleWithAnchor anchorId="key-facts" title="Key Facts" />
          <div className={styles.keyFactContainer}>
            <div>
              {keyFactLeftFields.map((field, index) => {
                if (
                  property.hasOwnProperty(field.fieldName) &&
                  property[field.fieldName]
                ) {
                  return (
                    <div key={index}>
                      <label>{field.label}:</label>
                      <span>{property[field.fieldName]}</span>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div>
              {keyFactRightFields.map((field, index) => {
                if (
                  property.hasOwnProperty(field.fieldName) &&
                  property[field.fieldName]
                ) {
                  return (
                    <div key={index}>
                      <label>{field.label}:</label>
                      <span>{property[field.fieldName]}</span>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
          {(property.brochureUrl || property.propertyFiles?.length > 0) && (
            <>
              <TitleWithAnchor anchorId="documents" title="Documents" />
              <div className={styles.documentsContainer}>
                {property.brochureUrl && (
                  <div className={styles.file}>
                    <DocumentIcon />
                    <a href={property.brochureUrl} rel="noopener noreferrer">
                      Download Brochure
                    </a>
                  </div>
                )}
                {property.propertyFiles?.map((file: PropertyFileInterface) => (
                  <div className={styles.file} key={file.fileName}>
                    <DocumentIcon />
                    <a
                      href={file.url}
                      rel="noopener noreferrer"
                    >{`Download ${file.fileName}`}</a>
                  </div>
                ))}
              </div>
            </>
          )}

          <TitleWithAnchor anchorId="location" title="Location" />
          <div className={styles.propertyAddress}>{propertyAddress}</div>
          {isShowMap ? (
            <div className={styles.mapPlaceholder}>
              <OpenStreetMap
                pushpins={[
                  {
                    latitude: property.isDisplayAddress
                      ? property.latitude
                      : property.latitude +
                        (Math.random() > 0.5 ? 0.005 : -0.005),
                    longitude: property.isDisplayAddress
                      ? property.longitude
                      : property.longitude +
                        (Math.random() > 0.5 ? 0.005 : -0.005),
                  },
                ]}
              />
            </div>
          ) : (
            <div
              className={styles.mapPlaceholder}
              onClick={() => {
                setIsShowMap(true);
              }}
            >
              <MapIcon />
              <button>VIEW ON MAP</button>
            </div>
          )}
          {property.mlsNumber && property.province === "Ontario" && (
            <p className={styles.disclaimer}>
              IDX information is provided exclusively for consumers’ personal,
              non-commercial use, that it may not be used for any purpose other
              than to identify prospective properties consumers may be
              interested in purchasing, and that data is deemed reliable but is
              not guaranteed accurate by the MLS.
            </p>
          )}
          {property.mlsNumber && property.province === "British Columbia" && (
            <p className={styles.disclaimer}>
              The data relating to real estate on this web site comes in part
              from the MLS Reciprocity program of the Real Estate Board of
              Greater Vancouver or the Fraser Valley Real Estate Board. Real
              estate listings held by participating real estate firms are marked
              with the MLS Reciprocity logo and detailed information about the
              listing includes the name of the listing agent. This
              representation is based in whole or part on data generated by the
              Real Estate Board of Greater Vancouver or the Fraser Valley Real
              Estate Board which assumes no responsibility for its accuracy. The
              materials contained on this page may not be reproduced without the
              express written consent of the Real Estate Board of Greater
              Vancouver or the Fraser Valley Real Estate Board.
            </p>
          )}
          {!property.mlsNumber && (
            <p className={styles.disclaimer}>
              Our valued client has expressed their desire to grant us an open
              listing for the sale of their properties. After careful
              consideration, they believe that by collaborating with a
              knowledgeable and experienced real estate professional, such as
              ourselves, the chances of finding the right buyer for their
              properties will be greatly enhanced. They have recognized our
              expertise and extensive network within the industry, deeming us as
              the ideal candidate to effectively represent and market their
              properties. The client is confident that with our guidance, a
              successful sale can be achieved in a timely manner.
            </p>
          )}
        </div>
        <div className={styles.requestInfoColumn}>
          <h2>REQUEST INFO</h2>
          <RequestInfo property={property} />
        </div>
      </div>
      <Footer />
    </Layout>
  );
}
